
// Libraries
import React, {useState, useEffect, useContext} from 'react'

// Components
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import Button from '../../components/button'
import MyBallots from '../../components/myBallots'
import PrivateRoute from '../../components/privateRoute'

// Services
import UserService from '../../services/user'

// Context
import NotificationContext from '../../contexts/notification'

export default function DashboardPage() {
	const userService = new UserService(),
	[userStats, setUserStats] = useState({}),
	{addNotification} = useContext(NotificationContext)

	useEffect(() => {
		userService.getUserDashboard().then((response) => {
			if(!response?.data?.user_stats || response?.errors) {
				addNotification('Failed to fetch dashboard', 'error')
			}

			setUserStats(response?.data?.user_stats)
		})
	}, [])

	return (
		<PrivateRoute>
			<Layout>
				<Seo title="Dashboard"/>
				<section className="user-dashboard">
					<div className="title-container">
						<h1 className="generic-template__title outline-title">My Ballots</h1>
						<Button to="/profile" xsBlock noDots>My Profile</Button>
					</div>
					<div className="panel">
						<div className="inner">
							<div className="stat-wrap">
								<h2 className="stat">Won</h2>
								{userStats?.winner_all_ballots && userStats?.winner_all_ballots.toLocaleString()}
							</div>
							<div className="stat-wrap">
								<h2 className="stat">Waiting List</h2>
								{userStats?.waiting_list_ballots && userStats?.waiting_list_ballots.toLocaleString()}
							</div>
							<div className="stat-wrap">
								<h2 className="stat">Active</h2>
								{userStats?.active_ballots && userStats?.active_ballots.toLocaleString()}
							</div>
							<div className="stat-wrap">
								<h2 className="stat">Unsuccessful</h2>
								{userStats?.unsuccessful_ballots && userStats?.unsuccessful_ballots.toLocaleString()}
							</div>
							<div className="stat-wrap">
								<h2 className="stat">Left</h2>
								{userStats?.removed_ballots && userStats?.removed_ballots.toLocaleString()}
							</div>
							<div className="stat-wrap">
								<h2 className="stat">Cancelled</h2>
								{userStats?.cancelled_ballots && userStats?.cancelled_ballots.toLocaleString()}
							</div>
						</div>
					</div>
				</section>

				<MyBallots />
			</Layout>
		</PrivateRoute>
	)
}