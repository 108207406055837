// Libraries
import * as React from 'react'

// Components
import Modal from './modal'
import Button from './button'

class ConfirmReenterBallot extends React.Component {
	render() {
		const { handleClose } = this.props

		return <Modal handleClose={handleClose} className="modal--leave-ballot" modalConfirm>
			<div className="modal-confirm">
				<h2>Are you sure you want to re-enter this ballot?</h2>
				<p>By re-entering the ballot you are accepting all chances of winning.</p>

				<ul className="button-wrap">
					<li><Button onClick={handleClose} hollow colorEndeavour>Cancel</Button></li>	
					<li><Button onClick={this.handleConfirm} colorEndeavour>Re-enter Ballot</Button></li>
				</ul>
			</div>
		</Modal>
	}

	handleConfirm = () => {
		const { handleConfirm } = this.props
		handleConfirm()
	}
}

export default ConfirmReenterBallot
