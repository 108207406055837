// Libraries
import React, { useState, useContext, useEffect } from 'react'
import moment from 'moment'

// Services
import BallotService from '../services/ballot'

// Components
import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ConfirmReenterBallot from './confirmReenterBallot'
import ConfirmLeaveBallot from './confirmLeaveBallot'
import Pagination from './pagination'
import Button from './button'

// Context
import NotificationContext from '../contexts/notification'

export default function BallotList(props) {
	const { searchQuery, loading, pagination, handlePaginationClick } = props
	const { addNotification } = useContext(NotificationContext)
	const [ballotToReenter, setBallotToReenter] = useState(null)
	const [ballotToRemove, setBallotToRemove] = useState(null)
	const [ballots, setBallots] = useState(props.ballots)
	const ballotService = new BallotService()

	useEffect(() => {
		setBallots(props.ballots)
	}, [props.ballots])

	const winningStatuses = [
		'Winner: Pending',
		'Winner: Accepted',
		'Winner: Rejected'
	]

	const statusClass = (status) => {
		let statusClass = ''

		if(status === 'Pending') {
			statusClass = 'event--ballot-pending'
		}
		else if(winningStatuses.includes(status)) {
			statusClass = 'event--ballot-successful'
		}
		else if(status === 'Unsuccessful') {
			statusClass = 'event--ballot-unsuccessful'
		}
		else if(status === 'Cannot Attend') {
			statusClass = 'event--ballot-cannot-attend'
		}
		else if(status === 'Waiting List') {
			statusClass = 'event--ballot-waiting-list'
		}
		else if(status === 'Cancelled') {
			statusClass = 'event--ballot-cancelled'
		}

		return statusClass
	}

	const statusHeading = (status) => {
		let statusHeading = ''

		if(status === 'Pending') {
			statusHeading = "You're in the ballot"
		}
		else if(winningStatuses.includes(status)) {
			statusHeading = "You're a winner"
		}
		else if(status === 'Unsuccessful') {
			statusHeading = 'Unsuccessful'
		}
		else if(status === 'Cannot Attend') {
			statusHeading = 'You left this ballot'
		}
		else if(status === 'Waiting List') {
			statusHeading = "You're on the waiting list"
		}
		else if(status === 'Cancelled') {
			statusHeading = 'Cancelled'
		}

		return statusHeading
	}

	const statusText = (status, applicationDeadline) => {
		let statusText = ''

		if(status === 'Pending') {
			statusText = `The planned ballot date is ${moment(applicationDeadline).format('MMMM D Y')}. Best of luck!`
		}
		else if(winningStatuses.includes(status)) {
			statusText = 'Please keep an eye on your inbox for further instructions.'
		}
		else if(status === 'Unsuccessful') {
			statusText = null
		}
		else if(status === 'Waiting List') {
			statusText = 'If a ticket becomes available, we\'ll let you know.'
		}
		else if(status === 'Cancelled') {
			statusText = 'Unfortunately, this event was cancelled.'
		}

		return statusText
	}

	async function handleTicketQtyUpdate(ballot, action) {
		const { max_tickets_per_transaction } = ballot?.event
		let { id, tickets_requested } = ballot
		let ballotResponse = null

		if (action === 'decrease' && tickets_requested > 0) {
			tickets_requested--
		} else if (action === 'increase') {
			tickets_requested++
		}

		if (tickets_requested <= max_tickets_per_transaction) {
			ballotResponse = await ballotService.update(id, { tickets_requested })
		}

		if (ballotResponse.success) {
			updateBallots(ballotResponse.data)
		} else {
			addNotification('Unable to update tickets', 'error')
		}
	}

	function updateBallots(updatedBallot) {
		let ballotsTemp = [...ballots]

		ballotsTemp.forEach((ballot, key) => {
			if (ballot.id === updatedBallot.id) {
				ballotsTemp[key] = {...ballot, ...updatedBallot}
			}
		})

		setBallots(ballotsTemp)
	}

	function leaveBallot() {
		setBallotToRemove(null)
		ballotService.remove(ballotToRemove)
			.then((response) => {
				if (response.success) {
					updateBallots(response.data.ballot)
				} else {
					addNotification('Unable to leave ballot', 'error')
				}
			}
		)
	}

	function reenterBallot() {
		setBallotToReenter(null)
		ballotService.reenter(ballotToReenter)
			.then((response) => {
				if (response.success) {
					updateBallots(response.data)
				} else {
					addNotification('Unable to re-enter ballot', 'error')
				}
			}
		)
	}

	return (
		<div className="event-list event-list--ballots">
			{!loading &&
				<div className="container">
					<Pagination pagination={pagination} handlePaginationClick={handlePaginationClick}/>
				</div>
			}

			{!loading ?
				<ul>
					{!!ballots && !!ballots?.length &&
					ballots.map((ballot) => {
						const disableActionButtons = ["Cancelled", "Cannot Attend"].includes(ballot?.status?.name)
						return (
							<li className={`event event--ballot ${statusClass(ballot?.status?.name)}`} itemScope itemType="https://schema.org/Event">
								<div className="container">
									<div className="event__title" itemProp="name">
										<span>{ballot?.event?.artist?.name}</span>
									</div>

									<div className="event__image">
										{ballot?.event?.logo &&
											<img src={ballot?.event?.logo} alt={`${ballot?.event?.artist?.name} - ${ballot?.event?.name}`}/>
										}
									</div>

									<div className="event__meta">
										<div className="event__artist event__artist--ballot" itemProp="performer"
											itemType="https://schema.org/PerformingGroup">
											<span>{ballot?.event?.name}</span>
										</div>

										<span className="event__meta__venue" itemScope itemProp="location"
											itemType="https://schema.org/Place">
													<span itemProp="name">{ballot?.event?.venue?.name ||
													ballot?.event?.venue_name}</span>
												</span>
										<span className="event__meta__date" itemProp="startDate"
											content={moment(ballot?.event?.starts).toISOString()}>
													{moment(ballot?.event?.starts).format('MMMM D, Y')}
												</span>
										{(["Pending", "Cancelled", "Cannot Attend"].includes(ballot?.status?.name)) 
											? ( 
												<span className={`event__tickets ${disableActionButtons && "event__tickets--disabled"}`}>	
													<button onClick={() => handleTicketQtyUpdate(ballot, 'decrease')} disabled={disableActionButtons || ballot?.tickets_requested === 1} className="action action--decrease">
														<FontAwesomeIcon icon={faMinus} />
													</button>
													<span className="qty">{ballot?.tickets_requested}</span>
													<button onClick={() => handleTicketQtyUpdate(ballot, 'increase')} disabled={disableActionButtons || ballot?.tickets_requested >= ballot?.event.max_tickets_per_transaction} className="action action--increase">
														<FontAwesomeIcon icon={faPlus} />
													</button> 
													<span>tickets</span>
												</span> 
											) : (
												<span>{ballot?.tickets_requested} tickets</span>
											)}
									</div>

									<div className="event__ballot-status">
										<p className="title">{statusHeading(ballot?.status?.name)}</p>

										{statusText(ballot?.status?.name) && (
											<p>{statusText(ballot?.status?.name, ballot?.event?.application_deadline)}</p>
										)}

										{['Pending', 'Waiting List'].includes(ballot?.status.name) && (
											<div className="event__leave">
												<Button onClick={() => setBallotToRemove(ballot?.id)} noDots hollow colorEndeavour >Leave this ballot</Button>
											</div>
										)}

										{ballot?.status.name === "Cannot Attend" && (
											<div className="event__reenter">
												<Button onClick={() => setBallotToReenter(ballot?.id)} noDots colorEndeavour>Re-enter this ballot</Button>
											</div>
										)}
								</div>

									<span itemProp="eventStatus" content="https://schema.org/EventScheduled"/>
									<span itemProp="eventAttendanceMode"
										content="https://schema.org/OfflineEventAttendanceMode"/>
									<span itemProp="description" content={ballot?.event?.synopsis}/>
									<span itemProp="endDate" content={moment(ballot?.event?.ends).toISOString()}/>
									<span itemProp="organizer" itemScope itemType="https://schema.org/Organization">
										<span itemProp="name" content={ballot?.event?.venue?.name}/>
										<span itemProp="url" content={ballot?.event?.venue?.website_url ||
										ballot?.event?.venue_website_url}/>
									</span>
								</div>
							</li>
						)}
					)}

					{(!ballots || !ballots.length) &&
						<div>
							{!searchQuery || searchQuery === '' ?
								<li className="no-ballots">
									<div className="container">
										<h3>You haven't applied for any ballots yet</h3>
										<p>Click the button below to explore our events</p>
										<Button to="/events">Browse events</Button>
									</div>
								</li>
								:
								<li className="no-ballots">
									<div className="container">
										<h3>No ballots found {searchQuery && `using the search term "${searchQuery}"`}
										</h3>
										<p>Click the button below to explore our events</p>
										<Button to="/events">Browse events</Button>
									</div>
								</li>
							}
						</div>
					}

					{ballotToRemove && <ConfirmLeaveBallot
						handleClose={() => setBallotToRemove(null)}
						handleConfirm={() => leaveBallot()} />}

					{ballotToReenter && <ConfirmReenterBallot
						handleClose={() => setBallotToReenter(null)}
						handleConfirm={() => reenterBallot()} />}
				</ul>
			:
				<div className="container">
					<h5>Ballots Loading...</h5>
				</div>
			}

			{!loading &&
				<div className="container">
					<Pagination hideCount={true} pagination={pagination} handlePaginationClick={handlePaginationClick}/>
				</div>
			}
		</div>
	)
}