
// Libraries
import React, {useState, useEffect} from 'react'

// Components
import BallotSearch from './ballotSearch'
import BallotList from './ballotList'
import ApiService from "../services/api";

const MyBallots = () => {
	// Initialise search query state
	const [searchQuery, setSearchQuery] = useState(false)

	const [ballots, setBallots] = useState([]),
		[loading, setLoading] = useState(true),
		[pagination, setPagination] = useState([]),
		[page, setPage] = useState(1)

	// Search component callback
	const handleSearch = event => {
		// Set search query state
		setSearchQuery(event.target.value)
	}

	const getUserBallots = () => {
		const apiService = new ApiService()

		let queryParams = ['filter']
		queryParams['per_page'] = 10
		queryParams['page'] = page

		if(searchQuery && searchQuery !== '') queryParams['search'] = searchQuery

		apiService.get(`users/${localStorage.getItem('loggedIn')}/ballots`, {}, queryParams).then((response) => {
			setBallots(response?.data?.data)
			setPagination(response?.data)
			setLoading(false)
		})
	}

	const handlePaginationClick = (page) => {
		setPage(page)
	}

	useEffect(() => {
		getUserBallots()
	}, [searchQuery, page])

	return (
		<section className="my-ballots">
			<div className="container">
				<h2 className="my-ballots__heading">My Ballots</h2>

				{!loading &&
					<BallotSearch onSearch={handleSearch}/>
				}
			</div>

			<BallotList ballots={ballots} searchQuery={searchQuery} loading={loading} pagination={pagination}
						handlePaginationClick={handlePaginationClick} />
		</section>
	)
}

export default MyBallots