
// Libraries
import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch  } from '@fortawesome/pro-regular-svg-icons'
import _ from 'lodash'

export default function BallotSearch({ onSearch }) {
	const handleChange = (event) => {
		onSearch(event)
	}

	const handleSubmit = (event) => {
		event.preventDefault()
	}

	return (
		<form className="event-search event-search--inline" onSubmit={handleSubmit}>
			<div className="event-search-wrap">
				<input type="text" placeholder="Search" className="event-search-wrap__input event-search-wrap__input--inline"
					   onChange={_.debounce(handleChange, 500)}/>
				<button className="event-search-wrap__submit event-search-wrap__submit--button event-search-wrap__submit--left" type="button">
					<FontAwesomeIcon icon={faSearch} title="Search"/>
				</button>
			</div>
		</form>
	)
}